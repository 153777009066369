.toast-notification {
    position: fixed !important;
    bottom: 0% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999 !important;
    transition: ease-in 0.1;
    width: 100%;
    min-width: 250px;
    max-width: 350px;
}
.react-bootstrap-table.table-responsive.table-dark 
{
    min-height: 279px;

}
.contact.col-lg-6.col-sm-12.mt-2 {margin-right: -73px;}
.contact.col-lg-6.col-sm-12.mt-3 {margin-right: -73px;}
.followers.col-lg-6.col-xl-3 
{
    margin-top: -27px;
}
.talent_image {
    width: 40px;
    height: 40px;
    background-color: #c7c7c7;
    border-radius: 20px;
    object-fit: cover;
}

.toast-notification i {
    font-size: 20px;
}

.create-event-modal textarea {
    resize: none;
}

.dropzone {
    border-radius: 10px;
    border: 2px dashed #1aae6f;
    text-align: center;
    cursor: grabbing;
}

.create-event-modal label {
    color: #000;
    font-weight: bolder;
}
.message_bookings{
    white-space: pre-line;
}
.gmap-wrapper {
    object-fit: contain;
    position: relative;
    width: 100%;
    background-color: aqua;
}

.truncate {
    min-width: 200px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-x: hidden;
    resize: horizontal
}

.chat {
    bottom: 20px;
    right: 40px;
    width: 100%;
    max-width: 350px;
    height: 500px;
}

.deleted-user {
    background-color: #900C3F;
    opacity: 0.4;
    transition: 0.4s;
}

.chat .chat-body {
    height: 400px;
    overflow-y: scroll;
}

.chat .chat-body::-webkit-scrollbar {
    display: none;
}

.chat .chat-footer i {
    cursor: pointer;
}

.fa-times {
    cursor: pointer !important;
}

i.clip-board {
    right: 25px;
    top: 0px;
    cursor: pointer !important;
}

/* .chat .chat-footer {
    bottom: 20px;
    right: 8%;
    z-index: 99;
} */

.user-sended-chat {
    width: 80%;
    min-height: 20px;
    float: left;
    position: relative;
}

.admin-sended-chat {
    width: 80%;
    min-height: 20px;
    float: right;
    position: relative;
}

.user-sended-chat p, .admin-sended-chat p {
    min-height: 10px;
    height: auto;
    max-height: 100%;
    border-radius: 10px 10px 0px 10px;
    padding: 5px 10px;
}

.user-sended-chat::before {
    color: #fff;
}

.admin-sended-chat::before {
    color: #5e72e4;
}

.user-sended-chat::before, .admin-sended-chat::before {
    content: '\f111';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 5px;
    right: -2%;
    bottom: 10px;
    position: absolute
}

.admin-sended-chat .username, .user-sended-chat .username {
    font-style: italic;
    font-size: 12px;
}

.event-images {
    object-fit: contain;
}

.event-images img {
    border-radius: 10px;
    max-width: 190px;
    display: block;
    margin: 0 auto;
}

.all-group-members {
    height: 80px;
    overflow-y: scroll;
}

.all-group-members div:hover {
    background-color: #5e72e4;
}

.toolbarClassName {
    padding: 20px;
    background-color: #1c345d;
    color: #4d7bca;
    border: 0px;
}

.editorClassName {
    padding: 20px;
    height: 250px;
}

.all-group-members::-webkit-scrollbar {
    display: none;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    background-color: #5e72e4 !important;
    border-radius: 50%;
    padding: 20px;
    background-size: 18px;
}

.detail-dialog label+span {
    margin-left: 8px;
}

.detail-dialog label {
    font-weight: 600;
    color: #5e72e4;
}

.avatar-group {
    display: flex;
}

.visually-hidden {
    display: none !important;
}

.badge-danger {
    color: #fff;
    background-color: #d50000;
}

.badge-success {
    color: #fff;
    background-color: #1aae6f;
}

.html-viewer {
    height: 350px;
    resize: none;
    overflow-y: scroll;
}

.html-viewer::-webkit-scrollbar {
    width: 5px;
}

.html-viewer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}

.html-viewer::-webkit-scrollbar-thumb {
    background: #5e72e4;
}

.html-viewer::-webkit-scrollbar-thumb:hover {
    background: #2a3ae2;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}

.toolbarClassName .rdw-option-wrapper {
    border: 1px solid #ffffff;
    background: #ffffff;
    color: #fff;
    border-radius: 4px;
    padding: 15px 8px;
}

.toolbarClassName .rdw-colorpicker-modal-options .rdw-option-wrapper {
    padding: 0px 0px;
}

.workout-details span {
    color: #000;
    font-size: 14px;
}

.ltg-gmap-wrapper {
    position: relative;
    height: 380px;
}

.upload-file-contianer {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border: 3px dashed #1aae6f;
}

.images i.fas.fa-times {
    font-size: 20px;
    color: #d50000;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.form-control {
    color: #000;
}

.form-control:focus {
    color: #000;
    border-color: transparent;
    box-shadow: 0px 0px 0px 2px rgb(70 123 233);
}

.prpnx-thumbnail {
    width: 100px;
    height: 100px;
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    display: inline-block;
    margin-top: 20px;
    position: relative;
    margin-right: 20px;
}

.prpnx-thumbnail img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 6px;
}

.prpnx-thumbnail-container {
    display: flex;
}

.prpnx-thumbnail-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 1px;
    width: 24px;
    height: 24px;
    border-radius: 35px;
    background-color: #af1717;
    cursor: pointer;
    transition: all .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.ltgr-searchmap-wrapper {
    width: 100%;
    position: relative;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid #cad1d7;
    margin-bottom: 20px;
}

.react-datepicker-popper {
    z-index: 10 !important;
}

.places-autocomplete-wrapper {
    position: absolute;
    z-index: 9;
    left: 50%;
    transform: translateX(-50%);
    margin: 15px auto 0px;
}

.places-inputbox {
    height: 40px;
    width: 50%;
    border-width: 1px;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-color: grey;
}

.ltgr-map {
    height: 100%;
}

.places-autocomplete-wrapper .location-search-input {
    box-shadow: 0px 4px 11px 0px rgb(0 0 0 / 20%);
    width: 320px;
    border-radius: 6px;
}

.autocomplete-dropdown-container {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 4px 11px 0px rgb(0 0 0 / 20%);
    position: absolute;
    width: 100%;
    top: 110%;
    max-height: 170px;
    overflow: auto;
}

.autocomplete-dropdown-container .suggestion-item {
    padding: 6px 12px;
    border-bottom: 1px solid #ddd;
    transition: all 0.4s;
    cursor: pointer;
}

.suggestion-item.suggestion-item--active {
    background-color: #c7cfff;
    color: #5e72e4;
}

.prpnx-readonly .form-control {
    background-color: #f2f2f2 !important;
    pointer-events: none;
}

form.prpnx-readonly .btn,
form.prpnx-readonly .filepicker-zone {
    display: none;
}

iframe {
    display: none;
}

.react-tel-input .form-control {
    width: 100%;
}

.filepicker-zone {
    border-radius: 10px;
    border: 2px dashed #1aae6f;
    text-align: center;
    cursor: grabbing;
    display: block;
    padding: 20px;
}

.filepicker-document.image--profile:after {
    content: "Profile Picture";
    position: absolute;
    top: 96%;
    width: calc(100% + 2px);
    color: #fff;
    text-transform: uppercase;
    font-size: 10.4px;
    text-align: center;
    background-color: #e62e2d;
    border-radius: 0px 0px 6px 6px;
    left: -1px;
    font-family: 'Cerebri Book';
    padding: 2px 0px;
  }
  

  .filepicker-document {
    width: 100px;
    height: 100px;
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    display: inline-block;
    margin-top: 20px;
    position: relative;
    margin-right: 20px;
  }
  
  .filepicker-document img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 6px;
  }
  
  .filepicker-document:first-child {
    margin-right: 0px;
  }
  
  .filepicker-document .btn-warning {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #af1717;
    padding: 1px;
    width: 28px;
    height: 28px;
    border-radius: 35px;
    cursor: pointer;
    transition: all 0.2s;
  }

  .profile-changer {
    position: absolute;
    background: #fff;
    border: 0;
    width: 30px;
    filter: invert(1);
    height: 30px;
    top: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 30px;
    cursor: pointer;
    z-index: 99;
    transition: all 0.3s;
    opacity: 0;
    transform: translateY(24px);
}

.filepicker-document:hover .profile-changer {
    opacity: 1;
    transform: translateY(0px);
}

body .table {
    color: #fff;
}