.chat-list {
	position: relative;
}
.chat-item {
	position: relative;
	margin-bottom: 20px;
	.chat-box {
		display: flex;
		.avatar {
			margin-right: 8px;
      margin-bottom: 18px;
		}
	}
}
.avatar {
  display: flex;
  background-color: #fff1da;
  flex: 0 0 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  color: #db9e3a;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}
.avatarHeader {
  display: flex;
  background-color: #fff1da;
  flex: 0 0 36px;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  color: #db9e3a;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}
.avatarProfile{
  display: flex;
    background-color: #fff1da;
    flex: 0 0 36px;
    height: 150px;
    width: 150px;
    align-items: center;
    justify-content: center;
    border-radius: 150px;
    color: #db9e3a;
    font-size: 60px;
    font-weight: 600;
    border: 1px solid #0088CB;
    text-transform: uppercase;
}
.chat-box {
	.ch-content {
    background-color: #edf0f8;
    padding: 10px 16px;
    max-width: 270px;
    border-radius: 10px;
    border-bottom-right-radius: 0px;
    min-width: 180px;
		h4 {
			margin-top: 0px;
			font-size: 16px;
			margin-bottom: 8px;
			font-weight: 600;
      color: rgb(103, 109, 124);
      text-transform: capitalize;
		}
		p {
      margin-bottom: 0px;
      color: #6a6f7b;
      line-height: 17px;
      font-size: 16px;
		}
	}
}
.chat-item-mine {
	.chat-box {
		.ch-content {
      background-color: #d9f2ff;
      border-radius: 10px;
      border-bottom-right-radius: 0px;
			h4 {
				color: #6e94a8;
			}
			p {
        color: #7691a0;
        
			}
		}
	}
}
.chat-item.chat-item-mine {
	.chat-box {
		flex-direction: row-reverse;
		align-items: flex-end;
		.avatar {
			margin-right: 0px;
			margin-left: 8px;
		}
	}
}

.avatar.light-grey {
  background-color: #e3e7ef;
  color: #797c84;
}

.avatar.light-green {
  background-color: #dbfdcd;
  color: #83bf54;
}

.avatar.light-blue {
  background-color: #d7e3ff;
  color: #6d83b7;
}

.avatar.light-purple {
  background-color: #f2e4ff;
  color: #9b48e2;
}

.avatar.light-pink {
  background-color: #ffcde3;
  color: #e03972;
}

.avatar.light-red {
  background-color: #ffc2c2;
  color: #d80a0a;
}

.no-results {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 45px 15px;
  width: 100%;
  margin-top: 30px;
  background-color: #fdfdfd;
  border-radius: 5px;
}

.no-results .svg-icon svg {
  width: 80px;
  height: 80px;
}

.no-results .svg-icon {
  margin-bottom: 16px;
}

.chat-footer input.form-control {
  background-color: #f3f4f5;
}